import React from 'react';
import { useSelector } from 'react-redux';
import { selectPeriod } from '../../reducers/filterSlice';

function removePourcent(text) {
  const newVal = text.replace('%', '');
  return parseInt(newVal, 10) / 100;
}

function calculatePourcentage(data, period) {
  if (data[period] === null || data['data_0'] === null) {
    return Infinity;
  }

  const data0 = data['data_0'].toString().includes('%') ? removePourcent(data['data_0']) : data['data_0'];
  const periodData = data[period].toString().includes('%') ? removePourcent(data[period]) : data[period];

  return Math.round(((data0 - periodData) / periodData) * 100);
}

const CustomTableItem = ({ value, sign = '' }) => {
  const period = useSelector(selectPeriod);

  const renderValue = (key) => {
    return value[key] !== null ? value[key].toLocaleString('fr-FR', { maximumFractionDigits: value.decimals }) : '--';
  };

  const renderPercentage = () => {
    const pourcent = calculatePourcentage(value, period);
    if (pourcent === Infinity) {
      return '–––';
    }
    return `${pourcent > 0 ? '+' : ''}${pourcent}%`;
  };

  return (
    value !== undefined && value !== null && value[period] !== null && !isNaN(value[period]) ? (
      <div className='c-card_data'>
        {period === 'data_0' ? (
          <>
            {renderValue(period)}{sign}
          </>
        ) : (
          <>
            {renderValue('data_0')}{sign}
            <span className='refer'>
              {renderValue(period)}{sign}
            </span>
            <span className={`pourcent ${calculatePourcentage(value, period) === Infinity ? 'neutral' : ''} ${calculatePourcentage(value, period) > 0 ? 'positive' : 'negative'}`}>
              {renderPercentage()}
            </span>
          </>
        )}
      </div>
    ) : (
      <div className='data'>- -</div>
    )
  );
};

export default CustomTableItem;