import React from 'react';
import PropTypes from 'prop-types';
import "../admin/AdminPanel.scss";

const CustomPopover = ({ isVisible, onDisconnect }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="user-options">
      <div className="user-options-item" onClick={onDisconnect}>
        Se déconnecter
      </div>
    </div>
  );
};

CustomPopover.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDisconnect: PropTypes.func.isRequired
};

export default CustomPopover;