import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import { setFilters } from "../../reducers/filterSlice";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const filters = ["Analytics", "Ads", "Meta"];

export default function CustomMultiSelect() {
    const [arrFilters, setArrFilters] = useState([]);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setArrFilters(typeof value === "string" ? value.split(",") : value);
    };

    useEffect(() => {
        dispatch(setFilters(arrFilters));
    }, [arrFilters]);

    return (
        <FormControl sx={{ m: 0, width: 10 }} id={"multi-select"}>
            <InputLabel id="demo-multiple-chip-label">Services</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={arrFilters}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {filters.map((filter) => (
                    <MenuItem key={filter} value={filter.toLowerCase()}>
                        <Checkbox checked={arrFilters.indexOf(filter.toLowerCase()) > -1} />
                        <ListItemText primary={filter} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
