import React from "react";
import "./CustomLoader.scss";

const SkeletonLoader = ({ type }) => {
    return (
        <div className={`skeleton-loader ${type}`}>
            {type === "canvas-wrapper" ? (
                <div className="canvas-wrapper-skeleton">
                    <span></span>
                    <div className="skeleton-doughnut"></div>
                </div>
            ) : (
                <>
                    <div className="skeleton-content">
                        <div className="c-card_top">
                            <div className="skeleton-text"></div>
                        </div>
                        <div className="skeleton-text"></div>
                    </div>
                    <div className="skeleton-image"></div>
                </>
            )}
        </div>
    );
};

export default SkeletonLoader;
