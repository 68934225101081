const constants = {}

constants.roles = [
    { value: 'accountant', id: 'accountant', label: 'Comptable' },
    { value: 'user', id: 'user', label: 'Utilisateur' },
    { value: 'admin', id: 'admin', label: 'Administrateur' }
];

constants.channels = [
    { value: 'website', id: 'website', label: 'Site internet' },
    { value: 'undefined', id: 'undefined', label: 'Inconnu' },
    { value: 'null', id: 'null', label: 'Inconnu' }
];

constants.engineTypes = [
    { value: 'dedge-smart', label: 'D-EDGE - Smart' },
    { value: 'dedge-attraction', label: 'D-EDGE - Attraction' },
    { value: 'reservit', label: 'Reserv\'it' },
]

constants.engineSources = [
    { value: 'website', label: 'Site internet' },
    { value: 'googlehpa', label: 'Métas - Google' }
]

constants.status = [
    { value: 'active', label: 'Actif' },
    { value: 'inactive', label: 'Inactif' },
    { value: 'archived', label: 'Archivé' },
    { value: 'progress', label: 'En cours' },
    { value: 'archived', label: 'Archivé' },
    { value: 'draft', label: 'Non commencé' },
    { value: 'waiting', label: 'En attente' },
    { value: 'closed', label: 'Terminé' },
    { value: 'difficulty', label: 'En difficulté' }
];
constants.types = [
    { value: 'fabrics', label: 'Tissus' },
    { value: 'production', label: 'Production' },
    { value: 'logistics', label: 'Logistique' }
]

constants.frequencies = [
    { value: 'days', label: 'jours' },
    { value: 'weeks', label: 'semaines' },
    { value: 'months', label: 'mois' }
]

constants.dataTypes = [
    { value: 'order', label: 'Lancement' },
    { value: 'project', label: 'Projet' },
    { value: 'customer', label: 'Client' },
    { value: 'provider', label: 'Fournisseur' }
]

constants.all = constants.roles.concat(constants.status);
constants.all = constants.all.concat(constants.types);
constants.all = constants.all.concat(constants.frequencies);
constants.all = constants.all.concat(constants.engineTypes);
constants.all = constants.all.concat(constants.engineSources);
constants.all = constants.all.concat(constants.dataTypes);
constants.all = constants.all.concat(constants.channels);

export default constants;
