import React from "react";
import CustomTooltip from "./CustomTooltip";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SimpleCustomCard = ({ data, tooltip, no_period, card_data }) => {
    if (card_data === undefined || card_data === "Chargement...") {
        return (
            <div className={`c-card ${no_period ? "small-card" : "initial"}`}>
                <Skeleton height={20} width={`80%`} />
                <Skeleton height={15} width={`60%`} style={{ marginTop: "10px" }} />
            </div>
        );
    }

    return (
        <div className={`c-card ${no_period ? "small-card" : "initial"} fade-in`}>
            <div className="c-card-content">
                <p className="c-card_data">{card_data}</p>
                <span className="c-card_title">{data.label}</span>
                <div className="c-card_tooltip">{tooltip !== false ? <CustomTooltip text={tooltip} /> : null}</div>
            </div>
        </div>
    );
};

const CustomCard = ({ data, period, no_period = false, tooltip = false, isTime = false, isInvert = false }) => {
    let percent;

    if (data.data_0 == null) {
        return <SimpleCustomCard data={data} tooltip={tooltip} no_period={no_period} card_data={"N/A"} />;
    }

    const removePercent = (text) => {
        const newVal = text.replace("%", "");
        return parseInt(newVal) / 100;
    };

    if (!data.data_0.toString().includes("%")) {
        percent = Math.round(((data.data_0 - data[period]) / data[period]) * 100);
    } else {
        percent = Math.round(((removePercent(data.data_0) - removePercent(data[period])) / removePercent(data[period])) * 100);
    }

    const format = !isTime
        ? (attr) => {
              return Number(attr).toLocaleString("fr-FR", {
                  maximumFractionDigits: 2,
              });
          }
        : (attr) => {
              return dayjs.duration(attr, "second").format("HH:mm:ss");
          };

    const show_full_card = !no_period && period !== "data_0";

    return show_full_card ? (
        <div className="c-card">
            <div className="c-card_top">
                <div className="c-row_top">
                    <p className="c-card_data">
                        {format(data.data_0)} {data.attribut ? data.attribut : null}
                    </p>
                    <span
                        className={`pourcent ${percent === Infinity ? "neutral" : ""} ${
                            isInvert ? `${percent >= 0 ? "negative" : "positive"}` : `${percent >= 0 ? "positive" : "negative"}`
                        }`}
                    >
                        {percent === Infinity ? (
                            "–––"
                        ) : (
                            <>
                                {percent > 0 ? "+" : null}
                                {percent}%
                            </>
                        )}
                    </span>
                </div>
                {tooltip !== false ? <CustomTooltip text={tooltip} /> : null}
            </div>
            <span className="c-card_title">{data.label}</span>
            <span className="refer">
                Contre{" "}
                <strong>
                    {format(data[period])} {data.attribut ? data.attribut : null}
                </strong>{" "}
                sur la période précédente
            </span>
        </div>
    ) : (
        <SimpleCustomCard
            data={data}
            tooltip={tooltip}
            no_period={no_period}
            card_data={`${format(data.data_0)} ${data.attribut ? data.attribut : ""}`}
        />
    );
};

export default CustomCard;
