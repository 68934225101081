import React, { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import CustomList from "../../custom/CustomList";
import CustomTableItem from "../../custom/CustomTableItem";
import { all_reports } from "../../../api";
import { useSelector, useDispatch } from "react-redux";
import { selectEndDate, selectFilters, selectStartDate, setInitial } from "../../../reducers/filterSlice";
import dayjs from "dayjs";
import { macroAnalytics, macroAds, macroMeta } from "../functionGlobal";
import { selectUser } from "../../../reducers/userSlice";
import SelectBar from "../../customers/SelectBar";
import CustomMultiSelect from "../../custom/CustomMultiSelect";
import "../../customers/tabs/CustomTabs.scss";
import CustomExportConversations from "../../custom/CustomExportConversationsFull";

const MacroView = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [livieData, setLivieData] = useState([]);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const [api, setApi] = useState([]);
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);
    const [dateRanges, setDateRanges] = useState([{ start_date: startDate, end_date: endDate }]);
    const [filtersList, setFiltersList] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const livieTabIndex = 1;
    var pageLoad = 0;

    const handleTabChange = (tabIndex) => {
        setTabValue(tabIndex);
    };

    const user = useSelector(selectUser);

    const colonneResa = React.useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",
                icon: "id",
                sortType: "basic",
                classname: "hotel-id",
            },
            {
                Header: "Hotel Code",
                sortType: "basic",
                accessor: "hotelCode",
                icon: "hotel-code",
                classname: "hotel-code",
                Cell: ({ value }) => (value ? value : "- -"),
            },
            {
                Header: "Nom de l'hôtel",
                accessor: "name",
                classname: "hotel-name",
                sortType: "basic",
                icon: "user",
            },
            {
                Header: "Utilisateurs",
                accessor: "analytics",
                classname: "analytics analytics-bg",
                sortType: (a, b) => {
                    a.values["analytics"] === null ? (a = 0) : (a = a.values["analytics"]["data_0"]);
                    b.values["analytics"] === null ? (b = 0) : (b = b.values["analytics"]["data_0"]);
                    if (a < b) return -1;
                    else if (a > b) return 1;
                    else return 0;
                },
                Cell: ({ cell: { value } }) => <CustomTableItem value={value} />,
            },
            {
                Header: "Réservations",
                accessor: "ads.data[0]",
                classname: "ads-bg",
                sortType: (a, b) => {
                    a.values["ads.data[0]"] === undefined ? (a = 0) : (a = a.values["ads.data[0]"]["data_0"]);
                    b.values["ads.data[0]"] === undefined ? (b = 0) : (b = b.values["ads.data[0]"]["data_0"]);
                    if (a < b) return -1;
                    else if (a > b) return 1;
                    return 0;
                },
                Cell: ({ cell: { value } }) => <CustomTableItem value={value} sign={"€"} />,
            },
            {
                Header: "ROI",
                accessor: "ads.data[1]",
                classname: "ads ads-bg",
                sortType: (a, b) => {
                    a.values["ads.data[1]"] === undefined ? (a = 0) : (a = a.values["ads.data[1]"]["data_0"]);
                    b.values["ads.data[1]"] === undefined ? (b = 0) : (b = b.values["ads.data[1]"]["data_0"]);
                    if (a < b) return -1;
                    else if (a > b) return 1;
                    return 0;
                },
                Cell: ({ cell: { value } }) => <CustomTableItem value={value} />,
            },
            {
                Header: "Appels",
                accessor: "ads.data[2]",
                classname: "ads-bg",
                sortType: (a, b) => {
                    a.values["ads.data[2]"] === undefined ? (a = 0) : (a = a.values["ads.data[2]"]["data_0"]);
                    b.values["ads.data[2]"] === undefined ? (b = 0) : (b = b.values["ads.data[2]"]["data_0"]);
                    if (a < b) return -1;
                    else if (a > b) return 1;
                    return 0;
                },
                Cell: ({ cell: { value } }) => <CustomTableItem value={value} />,
            },
            {
                Header: "Réservations",
                accessor: "meta.data[0]",
                classname: "meta meta-bg",
                sortType: (a, b) => {
                    a.values["meta.data[0]"] === undefined ? (a = 0) : (a = a.values["meta.data[0]"]["data_0"]);
                    b.values["meta.data[0]"] === undefined ? (b = 0) : (b = b.values["meta.data[0]"]["data_0"]);
                    if (a < b) return -1;
                    else if (a > b) return 1;
                    return 0;
                },
                Cell: ({ cell: { value } }) => <CustomTableItem value={value} sign={"€"} />,
            },
            {
                Header: "ROI",
                accessor: "meta.data[1]",
                classname: "roi meta-bg",
                sortType: (a, b) => {
                    a.values["meta.data[1]"] === undefined && isNaN(a.values["meta.data[1]"]) ? (a = 0) : (a = a.values["meta.data[1]"]["data_0"]);
                    b.values["meta.data[1]"] === undefined && isNaN(b.values["meta.data[1]"]) ? (b = 0) : (b = b.values["meta.data[1]"]["data_0"]);
                    if (a < b) return -1;
                    else if (a > b) return 1;
                    return 0;
                },
                Cell: ({ cell: { value } }) => <CustomTableItem value={value} />,
            },
        ],
        []
    );

    useEffect(() => {
        if (pageLoad === 0) {
            dispatch(setInitial());
            loadProperties();
            pageLoad++;
        }
    }, [user]);

    const loadProperties = () => {
        setIsLoaded(false);
        setApi([]);
        user.properties.forEach((property, index) => {
            loadData(property);
        });
    };

    const loadData = async (property) => {
        all_reports(property?.id, dayjs(startDate).format("YYYY-MM-DD"), dayjs(endDate).format("YYYY-MM-DD"))
            .then((response) => {
                setDateRanges(() => {
                    let data = [];
                    if (response.data.ads !== null) {
                        for (const row of response.data.ads) {
                            data.push(row.range);
                        }
                    } else if (response.data.meta !== null) {
                        for (const row of response.data.meta) {
                            data.push(row.range);
                        }
                    }
                    return data;
                });

                setApi((api) => [
                    ...api,
                    {
                        id: property.client_id,
                        hotelCode: property.meta_hotel_code,
                        name: property.name,
                        analytics: response.data.ga && response.data.ga !== null ? macroAnalytics(response.data.ga.data) : null,
                        ads: response.data.ads && response.data.ads !== null ? macroAds(response.data.ads) : null,
                        meta: response.data.meta && response.data.meta !== null ? macroMeta(response.data.meta, response.data.meta_cost) : null,
                        isMeta: property.meta_hotel_code !== null && property.meta !== false ? true : false,
                        isAds: response.data.ads !== null && property.ads !== false ? true : false,
                        isAnalytics: response.data.ga !== null && property.analytics !== false ? true : false,
                    },
                ]);
            })
            .catch((err) => {
                console.log(err);
                setIsLoaded(true);
            });
        setIsLoaded(true);
    };

    const colonneLivie = React.useMemo(
        () => [
            {
                Header: "Id",
                accessor: "hotel_id",
                icon: "id",
                sortType: "basic",
                classname: "hotel-id",
            },
            {
                Header: "Hotel Code",
                sortType: "basic",
                accessor: "hotelCode",
                icon: "hotel-code",
                classname: "hotel-code",
                Cell: ({ value }) => (value ? value : "- -"),
            },
            {
                Header: "Nom de l'hôtel",
                accessor: "name",
                classname: "hotel-name",
                sortType: "basic",
                icon: "user",
            },
            {
                Header: "Nb. de conversations",
                accessor: "nb_conversation",
                classname: "nb_conversation analytics-bg",
            },
            {
                Header: "En langues étrangères",
                accessor: "nb_real_conversations_foreigns",
                classname: "nb_real_conversations_foreigns ads-bg",
                Cell: ({ row }) => {
                    const nombreTotalDeConversations = row.original.nb_conversation;
                    const nombreDeConversationsEtrangeres = row.original.nb_real_conversations_foreigns;
                    const calculPourcentage =
                        nombreTotalDeConversations > 0 ? ((nombreDeConversationsEtrangeres / nombreTotalDeConversations) * 100).toFixed(0) : 0;
                    return <span>{calculPourcentage}%</span>;
                },
            },
            {
                Header: "Nb. clics vers résa.",
                accessor: "nb_clicks",
                classname: "nb_clicks meta-bg",
            },
            {
                Header: "Nb de tokens",
                accessor: "nb_token",
                classname: "nb_token analytics-bg",
            },
        ],
        []
    );

    useEffect(() => {
        if (tabValue === 1) {
            const loadLivieData = async () => {
                setIsLoading(true);
                const loadedData = [];
                const propertiesWithLivie = user.properties.filter((property) => property.livie_id);
                for (const property of propertiesWithLivie) {
                    if (property.livie_id) {
                        const dateDebut = dayjs(startDate).format("YYYY-MM-DD");
                        const dateFin = dayjs(endDate).format("YYYY-MM-DD");
                        const baseURL = process.env.REACT_APP_CHATBOT_URL;
                        const url = `${baseURL}${property.livie_id}?date1=${dateDebut}&date2=${dateFin}`;
                        try {
                            const response = await axios.get(url);
                            if (response.data && response.data.data.length > 0) {
                                const data = response.data.data[0];
                                loadedData.push({
                                    hotel_id: property.id,
                                    hotelCode: property.meta_hotel_code,
                                    name: property.name,
                                    nb_clicks: data.nb_clicks,
                                    nb_conversation: data.nb_real_conversation,
                                    nb_real_conversations_foreigns: data.nb_real_conversations_foreigns,
                                    nb_token: data.nb_token,
                                    limit_token: data.limit_token,
                                });
                            }
                        } catch (error) {
                            console.error("Error loading Livie data", error);
                        }
                    }
                }
                loadedData.sort((a, b) => a.hotel_id - b.hotel_id);
                setLivieData(loadedData);
                setIsLoading(false);
            };

            loadLivieData();
        }
    }, [tabValue, startDate, endDate, user.properties]);

    useEffect(() => {
        if (isLoaded) {
            const itemsMap = new Map();
            for (const item of api) {
                itemsMap.set(item.id, item);
            }

            const uniqueItemsArray = Array.from(itemsMap.values());

            uniqueItemsArray.sort((a, b) => a.id - b.id);

            if (filters.length === 0) {
                setFiltersList(uniqueItemsArray);
            } else {
                let filteredAndSorted = uniqueItemsArray.filter((property) => {
                    return filters.every((filter) => property[`is${filter.charAt(0).toUpperCase() + filter.slice(1)}`]);
                });
                setFiltersList(filteredAndSorted);
            }
        }
    }, [filters, api, isLoaded]);

    return (
        <article className="c-section-content">
            <div className="header-title macro">
                <h2>Vue Macro</h2>
                {tabValue !== 1 && (
                    <div className="macro-selector">
                        <CustomMultiSelect />
                    </div>
                )}
            </div>

            <div className="tab-container">
                <div className="tab-container-left">
                    <button className={`tab-link ${tabValue === 0 ? "tab-active" : ""}`} onClick={() => handleTabChange(0)}>
                        <span>Réservations</span>
                    </button>
                    <button className={`tab-link ${tabValue === 1 ? "tab-active" : ""}`} onClick={() => handleTabChange(1)}>
                        <span>Livie by Altelis</span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.75247 0.993605C4.8541 0.684585 5.29124 0.684586 5.39287 0.993606L6.4585 4.23378C6.49195 4.33547 6.5717 4.41523 6.67339 4.44868L9.91357 5.51431C10.2226 5.61594 10.2226 6.05308 9.91357 6.15471L6.67339 7.22034C6.5717 7.25379 6.49195 7.33355 6.4585 7.43524L5.39287 10.6754C5.29124 10.9844 4.8541 10.9844 4.75247 10.6754L3.68683 7.43524C3.65339 7.33355 3.57363 7.25379 3.47194 7.22034L0.231765 6.15471C-0.0772553 6.05308 -0.0772546 5.61594 0.231765 5.51431L3.47194 4.44867C3.57363 4.41523 3.65339 4.33547 3.68683 4.23378L4.75247 0.993605Z"
                                fill="currentColor"
                            />
                            <path
                                d="M10.5653 8.88694C10.6202 8.72014 10.8561 8.72014 10.911 8.88694L11.4862 10.6359C11.5043 10.6908 11.5473 10.7339 11.6022 10.7519L13.3512 11.3272C13.518 11.382 13.518 11.618 13.3512 11.6728L11.6022 12.248C11.5473 12.2661 11.5043 12.3092 11.4862 12.364L10.911 14.113C10.8561 14.2798 10.6202 14.2798 10.5653 14.113L9.9901 12.364C9.97205 12.3092 9.92899 12.2661 9.8741 12.248L8.1251 11.6728C7.9583 11.618 7.9583 11.382 8.1251 11.3272L9.8741 10.7519C9.92899 10.7339 9.97205 10.6908 9.9901 10.6359L10.5653 8.88694Z"
                                fill="currentColor"
                            />
                        </svg>
                    </button>
                    <div className="tab-background" style={{ transform: `translateX(${tabValue * 100}%)` }}></div>
                </div>
                <div className="tab-container-right">
                    {tabValue === 1 && <CustomExportConversations startDate={startDate} endDate={endDate} properties={user.properties} />}
                    {tabValue === 1 && <hr></hr>}
                    <SelectBar
                        loadData={loadProperties}
                        dateRanges={dateRanges}
                        hideFlexButton={tabValue === livieTabIndex}
                        tabLivieAffichage={tabValue === livieTabIndex}
                    />
                </div>
            </div>

            {isLoaded &&
                tabValue === 0 &&
                (api.length === 0 ? (
                    <div className="loader-container">
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <CustomList
                        entity={filtersList.length === 0 ? api : filtersList}
                        columns={colonneResa.filter((column) => !column.hide)}
                        activeFilters={colonneResa.filter((column) => !column.hide)}
                        className="table_list"
                        isFetchable={false}
                        isSelectable={false}
                        isPaginable={true}
                        isLarge={true}
                    />
                ))}

            {tabValue === 1 &&
                (isLoading ? (
                    <div className="loader-container">
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <CustomList
                        entity={livieData}
                        columns={colonneLivie}
                        className="table_list"
                        isFetchable={false}
                        isSelectable={false}
                        isPaginable={true}
                    />
                ))}
        </article>
    );
};

export default MacroView;
