import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Manrope",
  fonts: [
    {
      src: require("../../assets/fonts/Manrope-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../assets/fonts/Manrope-Medium.ttf"),
      fontWeight: "medium",
    },
    {
      src: require("../../assets/fonts/Manrope-SemiBold.ttf"),
      fontWeight: "600",
    },
    {
      src: require("../../assets/fonts/Manrope-Bold.ttf"),
      fontWeight: "bold",
    },
    {
      src: require("../../assets/fonts/Manrope-ExtraBold.ttf"),
      fontWeight: "800",
    },
  ],
});

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    position: "relative",
  },
  pageContent: {
    flexDirection: "column",
    position: "relative",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "595pt",
    height: "842pt",
  },
  contentWrapper: {
    padding: "10px 20px 10px 20px",
  },
  hr: {
    height: 1,
    backgroundColor: "#E7E7E7",
    width: "100%",
  },
  texteSection: {
    position: "absolute",
    left: "0px",
    right: "0px",
    marginHorizontal: "auto",
    textAlign: "center",
    justifyContent: "center",
    color: "#0D0D0D",
    fontFamily: "Manrope",
    fontWeight: "medium",
  },
  nomHotel: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 5,
  },
  titrePDF: {
    fontSize: 36,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 7.5,
  },
  textePeriode: {
    fontSize: 12,
    textAlign: "center",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "5px",
    marginVertical: "15px",
  },
  headerTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconeTitre: {
    width: 22.5,
    height: 22.5,
    marginRight: 10,
  },
  iconeTitreMetas: {
    width: 58,
    height: 16.5,
    marginRight: 10,
  },
  iconeTableau: {
    width: 10,
    height: 10,
  },
  copyrightWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    bottom: "20px",
  },
  copyrightTexte: {
    fontSize: "9px",
    backgroundColor: "#C3F5FF",
    fontFamily: "Manrope",
    textAlign: "center",
    paddingVertical: "3px",
    fontWeight: "medium",
    color: "#0D0D0D",
    borderRadius: "20px",
    width: "155px",
  },
  headerTitle: {
    fontSize: 24,
    fontFamily: "Manrope",
    textAlign: "center",
    fontWeight: "bold",
  },
  headerSubtitle: {
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Manrope",
    fontWeight: "medium",
    color: "#0D0D0D",
  },
  cardWrapper: {
    display: "flex",
    padding: "10px",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    borderRadius: "20px",
    border: "1px solid #E7E7E7",
    backgroundColor: "#FFFFFF",
  },
  cardHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  cardHeaderTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: "5px",
  },
  cardDataHeader: {
    fontSize: 24,
    textAlign: "center",
    fontWeight: "800",
    fontFamily: "Manrope",
    color: "#2C4DCB",
  },
  cardContentWrapper: {
    display: "flex",
    alignItems: "stretch",
    alignSelf: "stretch",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: "10px",
  },
  marginBottom: {
    marginBottom: "10px",
  },
  cardGrid4Wrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: "5px",
  },
  cardContentHeader: {
    display: "flex",
    padding: "10px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "5px",
    borderRadius: "10px",
    border: "1px solid #E7E7E7",
    backgroundColor: "#FFFFFF",
    width: "33%",
  },
  cardContent: {
    display: "flex",
    padding: "10px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderRadius: "10px",
    border: "1px solid #E7E7E7",
    backgroundColor: "#FFFFFF",
    width: "33%",
  },
  carContentTexte: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    alignItems: "flex-start",
    marginTop: "5px",
  },
  cardTitre: {
    fontSize: "11px",
    color: "#0D0D0D",
    fontFamily: "Manrope",
    fontWeight: "600",
  },
  cardTitreSmall: {
    fontSize: "10px",
    color: "#0D0D0D",
    fontFamily: "Manrope",
    fontWeight: "600",
  },
  cardBreakTexte: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  cardSubtitle: {
    fontSize: "9px",
    color: "#252525",
    fontFamily: "Manrope",
    fontWeight: "medium",
    opacity: "0.5",
    flexWrap: "wrap",
  },
  cardTooltip: {
    fontSize: "8px",
    color: "#252525",
    fontFamily: "Manrope",
    fontWeight: "medium",
    opacity: "0.5",
  },
  cardDataWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  cardDataBleu: {
    color: "#1B35AB",
    fontFamily: "Manrope",
    fontWeight: "bold",
  },
  cardDataAltelis: {
    color: "#3B61E1",
    fontFamily: "Manrope",
    fontWeight: "bold",
  },
  cardDataPourcent: {
    display: "flex",
    padding: "2.5px 5px",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px",
    color: "#FFFFFF",
    fontFamily: "Manrope",
    fontWeight: "medium",
    fontSize: "12px",
  },
  cardDataPourcentBleu: {
    backgroundColor: "#3B61E1",
  },
  cardDataPourcentJaune: {
    backgroundColor: "#E7A85A",
  },
  cardDataPourcentRose: {
    backgroundColor: "#F4709F",
  },
  cardGrid4: {
    display: "flex",
    padding: "10px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignSelf: "stretch",
    gap: "10px",
    borderRadius: "10px",
    border: "1px solid #E7E7E7",
    backgroundColor: "#FFFFFF",
    width: "calc(25% - 10px)",
  },
  cardGrid4Content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "5px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "#E7E7E7",
    borderRadius: "10px",
    fontFamily: "Manrope",
    fontWeight: "medium",
    overflow: "hidden",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    backgroundColor: "#FFFFFF",
    borderBottomWidth: 1,
    borderBottomColor: "#E7E7E7",
  },
  tableRowHeader: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F3F3F3",
    borderBottomWidth: 1,
    borderBottomColor: "#E7E7E7",
    padding: "5px 10px",
    textAlign: "center",
  },
  tableauHeaderWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 5px",
    display: "flex",
    gap: "5px",
  },
  tableCellHeader: {
    fontWeight: "600",
    fontSize: "10px",
  },
  tableCellPays: {
    fontSize: 10,
    fontFamily: "Manrope",
    padding: "2px 5px",
  },
  tableCell: {
    fontSize: 10,
    fontFamily: "Manrope",
    textAlign: "center",
  },
  tableCellAltelis: {
    color: "#2C4DCB",
    fontWeight: "bold",
  },
  twoWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
  },
  twoContentHeader: {
    display: "flex",
    padding: "10px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "5px",
    borderRadius: "10px",
    borderWidth: 1,
    borderColor: "#E7E7E7",
    backgroundColor: "#FFFFFF",
  },
  twoSubContent: {
    display: "flex",
    gap: "10px",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "10px",
    borderRadius: "10px",
    borderWidth: 1,
    borderColor: "#E7E7E7",
    backgroundColor: "#FFFFFF",
    width: "50%",
  },
  line: {
    backgroundColor: "#E7E7E7",
    height: 1,
    alignSelf: "stretch",
    marginVertical: 5,
  },
});
