// React and Hooks
import React, { useEffect, useState, useMemo } from "react";

// External Libraries
import axios from "axios";
import dayjs from "dayjs";
import Modal from "react-modal";

// Custom Components
import CustomList from "../../../custom/CustomList";
import CustomTooltip from "../../../custom/CustomTooltip";
import CustomLoader from "../../../custom/CustomLoader";

// Assets
import NbConversations from "../../../../assets/images/chatbot-livie/nb_conversations.svg";
import NbConversationsEtrangere from "../../../../assets/images/chatbot-livie/nb_conversations_etrangere.svg";
import NbClicksChatbot from "../../../../assets/images/chatbot-livie/click-chatbot.svg";
import TauxUtilisation from "../../../../assets/images/chatbot-livie/taux-utilisation.svg";
import LogoLivieAI from "../../../../assets/icons/livie-ai--stars.svg";
import LogoLivieHeader from "../../../../assets/icons/livie-ai--header.svg";
import LogoLivieChatbot from "../../../../assets/icons/livie-ai--chatbot.svg";
import LivieChatbotClose from "../../../../assets/icons/livie-ai-chatbot--close.svg";
import IconeFleche from "../../../../assets/icons/arrow-small-right.svg";

// Sample Data
import donneesFictives from "../../../admin/sample/chatbot.json";

// Styles
import "./TabLivie.scss";

Modal.setAppElement("#root");

const TabLivie = ({ tooltip, property, startDate, endDate, isAdmin }) => {
    const [dataAPI, setDataAPI] = useState(null);
    const [conversationDetails, setConversationDetails] = useState([]);
    const [pourcentageLangueEtrangere, setPourcentageLangueEtrangere] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [conversationIdLivie, setConversationIdLivie] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentConversationIndex, setCurrentConversationIndex] = useState(0);
    const [modalContentOpacity, setModalContentOpacity] = useState(1);
    const [hoverPrev, setHoverPrev] = useState(false);
    const [hoverNext, setHoverNext] = useState(false);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [apiError, setApiError] = useState(null);

    const affichageDateConversation = (dateString) => {
        return dayjs(dateString).format("DD MMM YYYY [à] HH[h]mm");
    };

    const columns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "chatId",
                icon: "id",
            },
            {
                Header: "Date de la conversation",
                accessor: "updatedAt",
                icon: "calendar",
                Cell: ({ cell: { value } }) => affichageDateConversation(value),
            },
            {
                Header: "Extrait de la conversation",
                accessor: "userQuestion",
                icon: "conversation",
                Cell: ({ cell: { value } }) => <div className="conversation-cell">{value && value.charAt(0).toUpperCase() + value.slice(1)}</div>,
            },
            {
                Header: "",
                accessor: "actions",
                Cell: ({ row }) => {
                    return (
                        <button onClick={() => ouvertureModale(row.original.chatId)} className="view-conversation">
                            <img src={LogoLivieAI} alt="Icone Livie AI" />
                            Voir la conversation
                        </button>
                    );
                },
            },
        ],
        []
    );

    const conversationLivie = (conversationId) => {
        if (!conversationIdLivie) return <p>Aucune conversation sélectionnée.</p>;

        const conversation = conversationDetails.find((c) => c.chatId === conversationId);
        if (!conversation) return <p>Conversation introuvable.</p>;

        return conversation.events
            .map((event, index) => {
                const isUserInput = event.type === "user_input";
                const text = event.params?.input || event.params?.text || event.params?.result || "";
                const hasText = text.trim().length > 0;

                if (!hasText) return null;

                const texteFormat = text
                    .split(/(\*\*.*?\*\*)/)
                    .map((part, index) => (part.match(/^\*\*(.*?)\*\*$/) ? <strong key={index}>{part.slice(2, -2)}</strong> : part));

                return (
                    <div key={index} className={isUserInput ? "modal-livie-text-users" : "modal-livie-text-ai"}>
                        {!isUserInput && <img src={LogoLivieChatbot} alt="Logo Livie AI" />}
                        <p>{texteFormat}</p>
                    </div>
                );
            })
            .filter(Boolean);
    };

    const fermetureModale = () => {
        setIsModalOpen(false);
        setConversationIdLivie(null);
        setSelectedChatId(null);
    };

    const goToNextConversation = () => {
        const newIndex = currentConversationIndex + 1;
        if (newIndex < conversationDetails.length) {
            changeConversation(newIndex);
        }
    };

    const goToPreviousConversation = () => {
        const newIndex = currentConversationIndex - 1;
        if (newIndex >= 0) {
            changeConversation(newIndex);
        }
    };

    const changeConversation = (newIndex) => {
        setModalContentOpacity(0);

        setTimeout(() => {
            setCurrentConversationIndex(newIndex);
            setConversationIdLivie(conversationDetails[newIndex].chatId);
            setModalContentOpacity(1);
        }, 200);
    };

    useEffect(() => {
        if (selectedChatId !== null) {
            const index = conversationDetails.findIndex((c) => String(c.chatId) === String(selectedChatId));
            if (index !== -1) {
                setCurrentConversationIndex(index);
                setConversationIdLivie(selectedChatId);
                setIsModalOpen(true);
            } else {
                console.error("Conversation ID not found:", selectedChatId);
            }
        }
    }, [selectedChatId, conversationDetails]);

    const ouvertureModale = (chatId) => {
        setSelectedChatId(chatId);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            let conversationDetailsTemp = [];
            let hotelStats = null;

            if (property && property.livie_id) {
                const dateDebut = new Date(startDate).toISOString().slice(0, 10);
                const dateFin = new Date(endDate);
                dateFin.setDate(dateFin.getDate() + 1);
                const baseURL = process.env.REACT_APP_CHATBOT_URL;
                const url = `${baseURL}${property.livie_id}?date1=${dateDebut}&date2=${dateFin.toISOString().slice(0, 10)}`;
                try {
                    const response = await axios.get(url);
                    if (response.data && response.data.data) {
                        hotelStats = response.data.data[0];
                        conversationDetailsTemp = response.data.data.flatMap((d) =>
                            d.chats.map((chat) => {
                                const updatedDate = new Date(chat.chat.updated_at);
                                updatedDate.setHours(updatedDate.getHours() + 2);
                                return {
                                    chatId: chat.chat.id,
                                    updatedAt: updatedDate.toISOString(),
                                    userQuestion: chat.events.find((event) => event.type === "user_input")?.params.input || "",
                                    events: chat.events,
                                };
                            })
                        );
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des données de l'API", error);
                    setApiError(true);
                }
            } else {
                hotelStats = {
                    ...donneesFictives,
                    nb_real_conversation: donneesFictives.chats.length,
                };
                conversationDetailsTemp = donneesFictives.chats;
            }

            if (hotelStats && hotelStats.chats) {
                setDataAPI(hotelStats);
                setConversationDetails(conversationDetailsTemp.sort((a, b) => dayjs(b.updatedAt).diff(dayjs(a.updatedAt))));
                const nbConversations = hotelStats.chats.length || 0;
                const nbConversationsEtrangeres = hotelStats.nb_real_conversations_foreigns || 0;
                const pourcentage = nbConversations > 0 ? (nbConversationsEtrangeres / nbConversations) * 100 : 0;
                setPourcentageLangueEtrangere(Math.round(pourcentage));
            } else {
                setDataAPI({
                    nb_real_conversation: "Non dispo.",
                    nb_clicks: "Non dispo.",
                    nb_token: "Non dispo.",
                });
                setConversationDetails([]);
                setPourcentageLangueEtrangere(0);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [property, startDate, endDate]);

    useEffect(() => {
        if (dataAPI) {
            const nbConversations = dataAPI.nb_real_conversation;
            const nbConversationsEtrangeres = dataAPI.nb_real_conversations_foreigns;
            if (nbConversations > 0) {
                const pourcentage = (nbConversationsEtrangeres / nbConversations) * 100;
                setPourcentageLangueEtrangere(Math.round(pourcentage));
            }
        }
    }, [dataAPI]);

    const donneesLivie = [
        {
            id: "nb_real_conversation",
            title: "Nb. de conversations",
            value: dataAPI?.chats?.length ? dataAPI.chats.length : "Non dispo.",
            tooltip: "Indique le nombre total de conversations engagées via le chatbot",
            image: NbConversations,
            imageAlt: "Illustration Nb. de conversations Livie",
        },
        {
            id: "pourcentage_langue_etrangere",
            title: "Conversations en langue étrangère",
            value: dataAPI ? `${pourcentageLangueEtrangere} %` : "Non dispo.",
            tooltip: "Montre le pourcentage des conversations menées dans une langue autre que le français",
            image: NbConversationsEtrangere,
            imageAlt: "Illustration Nb. de conversations en langue étrangère",
        },
        {
            id: "nb_clicks",
            title: "Nb. de clics vers le site de réservation via le chatbot",
            value: dataAPI ? dataAPI.nb_clicks : "Non dispo.",
            tooltip: "Indique le total des clics effectués vers le site de réservation via le chatbot.",
            image: NbClicksChatbot,
            imageAlt: "Illustration du nombre de clics vers le site de réservation depuis le chatbot",
        },
        ...(isAdmin
            ? [
                  {
                      id: "nb_token",
                      title: "Tokens consommés",
                      value: dataAPI ? dataAPI.nb_token : "Non dispo.",
                      image: TauxUtilisation,
                      tooltip: "Affiche le nombre de tokens consommés",
                  },
              ]
            : []),
    ];

    const donneesLivieFictives = [
        {
            id: "nb_real_conversation",
            title: "Nb. de conversations",
            value: 89,
            tooltip: "Indique le nombre total de conversations engagées via le chatbot",
            image: NbConversations,
            imageAlt: "Illustration Nb. de conversations Livie",
        },
        {
            id: "pourcentage_langue_etrangere",
            title: "Conversations en langue étrangère",
            value: dataAPI ? `${pourcentageLangueEtrangere} %` : "Non dispo.",
            tooltip: "Montre le pourcentage des conversations menées dans une langue autre que le français",
            image: NbConversationsEtrangere,
            imageAlt: "Illustration Nb. de conversations en langue étrangère",
        },
        {
            id: "nb_clicks",
            title: "Nb. de clics vers le site de réservation via le chatbot",
            value: dataAPI ? dataAPI.nb_clicks : "Non dispo.",
            tooltip: "Indique le total des clics effectués vers le site de réservation via le chatbot.",
            image: NbClicksChatbot,
            imageAlt: "Illustration du nombre de clics vers le site de réservation depuis le chatbot",
        },
        ...(isAdmin
            ? [
                  {
                      id: "nb_token",
                      title: "Tokens consommés",
                      value: dataAPI ? dataAPI.nb_token : "Non dispo.",
                      image: TauxUtilisation,
                      tooltip: "Affiche le nombre de tokens consommés",
                  },
              ]
            : []),
    ];

    return property && property.livie_id ? (
        <div className="tab-livie">
            <div className="header-title livie stats-livie">
                <h2>Statistiques Livie</h2>
                <p>
                    Découvrez une vision détaillée des interactions avec Livie, offrant un aperçu clair des performances et des tendances de
                    conversation
                </p>
            </div>
            <section className="section-livie-wrapper">
                <div className="article-wrapper">
                    {isLoading ? (
                        donneesLivie
                            .filter((item) => item.type !== "doughnut")
                            .map((item) => (
                                <div key={item.id} className="loading-data">
                                    <CustomLoader />
                                </div>
                            ))
                    ) : (
                        <>
                            {donneesLivie.map((item) => (
                                <article key={item.id} className={`c-card initial ${isLoading ? "loading" : "fade-in"}`} id={item.id}>
                                    <div className="c-card-content">
                                        <div className="c-card_top livie">
                                            {isLoading ? <p className="c-card_data">Chargement...</p> : <p className="c-card_data">{item.value}</p>}
                                            {tooltip !== false && <CustomTooltip text={item.tooltip} />}
                                        </div>
                                        <span className="c-card_title">{item.title}</span>
                                    </div>
                                    {!isLoading && <img src={item.image} alt={item.imageAlt} />}
                                </article>
                            ))}
                        </>
                    )}
                </div>
            </section>
            {!isLoading && (
                <div className="table-wrapper-livie fade-in">
                    <div className="header-title livie chat-livie">
                        <h3>Vos conversations Livie</h3>
                        <p>
                            Explorez les conversations individuelles pour une compréhension approfondie des besoins et questions des utilisateurs,
                            enrichissant ainsi votre stratégie de communication
                        </p>
                    </div>
                    {apiError ? (
                        <div className="login-error">Il n'y a pas de conversations pour cette période.</div>
                    ) : conversationDetails.length === 0 ? (
                        <div className="livie-row">
                            <p>
                                Les données sont actuellement en cours de chargement. Si ce message persiste, veuillez revenir plus tard ou contacter
                                notre support pour assistance.
                                <br />
                                Vous pouvez nous joindre à l'adresse <a href="mailto:support@altelis.com">support@altelis.com</a>.
                            </p>
                        </div>
                    ) : (
                        <CustomList
                            key={JSON.stringify(conversationDetails)}
                            columns={columns}
                            entity={conversationDetails}
                            pageSizeOptions={[10, 15]}
                            isSmall={true}
                            isFetchable={false}
                            isSelectable={false}
                        />
                    )}
                </div>
            )}
            <Modal isOpen={isModalOpen} onRequestClose={fermetureModale} contentLabel="Détails de la conversation">
                <div className="modal-livie-wrapper">
                    <div className={`conversation-navigation ${isAdmin ? "admin-hidden" : ""}`}>
                        <button
                            id="conversation-precedente"
                            onClick={goToPreviousConversation}
                            disabled={currentConversationIndex <= 0}
                            style={{
                                opacity: currentConversationIndex <= 0 ? 0 : hoverPrev ? 0.5 : 1,
                                cursor: currentConversationIndex <= 0 ? "default" : "pointer",
                            }}
                            onMouseEnter={() => setHoverPrev(true)}
                            onMouseLeave={() => setHoverPrev(false)}
                        >
                            <img src={IconeFleche} alt="Précédent" style={{ transform: "rotate(180deg)" }} />
                        </button>
                        <button
                            id="conversation-suivante"
                            onClick={goToNextConversation}
                            disabled={currentConversationIndex >= conversationDetails.length - 1}
                            style={{
                                opacity: currentConversationIndex >= conversationDetails.length - 1 ? 0 : hoverNext ? 0.5 : 1,
                                cursor: currentConversationIndex >= conversationDetails.length - 1 ? "default" : "pointer",
                            }}
                            onMouseEnter={() => setHoverNext(true)}
                            onMouseLeave={() => setHoverNext(false)}
                        >
                            <img src={IconeFleche} alt="Suivant" />
                        </button>
                    </div>
                    <div className="modal-livie-header">
                        <div className="modal-livie-header-icon">
                            <img src={LogoLivieHeader} alt="Logo Livie AI Header"></img>
                            <p>
                                Conversation <span style={{ opacity: modalContentOpacity }}>#{conversationIdLivie}</span>
                            </p>
                        </div>
                        <button onClick={fermetureModale}>
                            <img src={LivieChatbotClose} alt="Icone Livie Close"></img>
                        </button>
                    </div>
                    <div className="modal-livie-content" style={{ opacity: modalContentOpacity }}>
                        {conversationLivie(conversationIdLivie)}
                    </div>
                </div>
            </Modal>
        </div>
    ) : (
        <div className="tab-livie">
            <div className="header-title livie stats-livie">
                <h2>Données fictives de Livie</h2>
                <p>
                    Plongez dans un aperçu dynamique des interactions avec Livie grâce à nos données de démonstration. Laissez-vous inspirer par les
                    possibilités infinies qu'offre notre chatbot intelligent pour révolutionner votre service client.
                </p>
            </div>
            <section className="section-livie-wrapper">
                <div className="article-wrapper">
                    {isLoading ? (
                        donneesLivieFictives
                            .filter((item) => item.type !== "doughnut")
                            .map((item) => (
                                <div key={item.id} className="loading-data">
                                    <CustomLoader />
                                </div>
                            ))
                    ) : (
                        <>
                            {donneesLivieFictives.map((item) => (
                                <article key={item.id} className={`c-card initial ${isLoading ? "loading" : "fade-in"}`} id={item.id}>
                                    <div className="c-card-content">
                                        <div className="c-card_top livie">
                                            {isLoading ? <p className="c-card_data">Chargement...</p> : <p className="c-card_data">{item.value}</p>}
                                            {tooltip !== false && <CustomTooltip text={item.tooltip} />}
                                        </div>
                                        <span className="c-card_title">{item.title}</span>
                                    </div>
                                    {!isLoading && <img src={item.image} alt={item.imageAlt} />}
                                </article>
                            ))}
                        </>
                    )}
                </div>
            </section>
            <div className="table-wrapper-livie fade-in">
                <div className="header-title livie chat-livie">
                    <h3>Démonstration de Livie</h3>
                    <p>
                        Naviguez à travers des échanges avec Livie pour découvrir comment notre IA engage les utilisateurs, répond avec précision à
                        leurs interrogations et enrichit l'expérience client. C'est une occasion unique de voir comment Livie peut devenir un pilier
                        central de votre stratégie de communication digitale.
                    </p>
                </div>
                <CustomList
                    key={JSON.stringify(conversationDetails)}
                    columns={columns}
                    entity={donneesFictives.chats}
                    pageSizeOptions={[10, 15]}
                    isSmall={true}
                    isFetchable={false}
                    isSelectable={false}
                />
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={fermetureModale} contentLabel="Détails de la conversation">
                <div className="modal-livie-wrapper">
                    <div className={`conversation-navigation ${isAdmin ? "admin-hidden" : ""}`}>
                        <button
                            id="conversation-precedente"
                            onClick={goToPreviousConversation}
                            disabled={currentConversationIndex <= 0}
                            style={{
                                opacity: currentConversationIndex <= 0 ? 0 : hoverPrev ? 0.5 : 1,
                                cursor: currentConversationIndex <= 0 ? "default" : "pointer",
                            }}
                            onMouseEnter={() => setHoverPrev(true)}
                            onMouseLeave={() => setHoverPrev(false)}
                        >
                            <img src={IconeFleche} alt="Précédent" style={{ transform: "rotate(180deg)" }} />
                        </button>
                        <button
                            id="conversation-suivante"
                            onClick={goToNextConversation}
                            disabled={currentConversationIndex >= conversationDetails.length - 1}
                            style={{
                                opacity: currentConversationIndex >= conversationDetails.length - 1 ? 0 : hoverNext ? 0.5 : 1,
                                cursor: currentConversationIndex >= conversationDetails.length - 1 ? "default" : "pointer",
                            }}
                            onMouseEnter={() => setHoverNext(true)}
                            onMouseLeave={() => setHoverNext(false)}
                        >
                            <img src={IconeFleche} alt="Suivant" />
                        </button>
                    </div>
                    <div className="modal-livie-header">
                        <div className="modal-livie-header-icon">
                            <img src={LogoLivieHeader} alt="Logo Livie AI Header"></img>
                            <p>
                                Conversation <span style={{ opacity: modalContentOpacity }}>#{conversationIdLivie}</span>
                            </p>
                        </div>
                        <button onClick={fermetureModale}>
                            <img src={LivieChatbotClose} alt="Icone Livie Close"></img>
                        </button>
                    </div>
                    <div className="modal-livie-content" style={{ opacity: modalContentOpacity }}>
                        {conversationLivie(conversationIdLivie)}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default TabLivie;
