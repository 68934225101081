import React from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Tooltip from '@mui/material/Tooltip';

const CustomTooltip = ({text}) => {
    return(
        <Tooltip title={text} placement="top" className="c-tooltip">
            <QuestionMarkIcon />
        </Tooltip>
    )
}

export default CustomTooltip;
