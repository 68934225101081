import React, {useState} from 'react';
import constants from "../../assets/constants/constants";

const CustomTag = ({value, className = '', label}) => {

    const [matchedConstant, setMatchedConstant] = useState(null);

    React.useEffect(() => {
        setMatchedConstant(constants.all.find((e) => { return e.value === value }));
    }, [value]);

    return matchedConstant ? (
        <span className={'custom-tag ' + matchedConstant.value + className}>{label ? label : matchedConstant.label}</span>
    ) : <span className={'custom-tag ' + className}>{label ? label : value}</span>
}

export default CustomTag;