import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { CircularProgress } from "@mui/material";
const CustomerList = lazy(() => import("./CustomerList"));
const CustomerPanel = lazy(() => import("../../customers/CustomerPanel"));

const Customers = () => {
    return (
        <Suspense
            fallback={
                <div className="loader-container">
                    <CircularProgress size={30} />
                </div>
            }
        >
            <Routes>
                <Route path=":propertyId" element={<CustomerPanel adminMode />} />
                <Route path="*" element={<CustomerList />} />
            </Routes>
        </Suspense>
    );
};

export default Customers;
