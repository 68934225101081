import dayjs from "dayjs";
import CustomTag from "./CustomTag";
import React from "react";

const CustomOption = ({ innerProps, isDisabled, label, ...props }) => {

    return (
        !isDisabled ? (
            props.data.date_range ? (
                <div {...innerProps} className='custom-tag-container'>
                    <div className="invoice-option-select">
                        <div className='invoice-option-select-infos'>
                            <span className='invoice-number'>{label}</span>
                        </div>
                        <span className='invoice-customer'>Du <strong>{dayjs(props.data.date_range.start_date).format('DD/MM/YYYY')}</strong> au <strong>{dayjs(props.data.date_range.end_date).format('DD/MM/YYYY')}</strong></span>
                    </div>
                </div>
            ) : (
                <div {...innerProps} className='custom-tag-container'>
                    <CustomTag value={label} className={props.data.type} />
                </div>
            )
        ) : null
    );
}

export default CustomOption;