import React, { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";

const CustomExportConversations = ({ startDate, endDate, property }) => {
    const [exportFormat, setExportFormat] = useState("json");
    const [isLoading, setIsLoading] = useState(false);

    const handleExport = async () => {
        if (!property || !property.livie_id) return;
        setIsLoading(true);
        const dateDebut = dayjs(startDate).format("YYYY-MM-DD");
        const dateFin = dayjs(endDate).add(1, "day").format("YYYY-MM-DD");
        const baseURL = process.env.REACT_APP_CHATBOT_URL;

        let allConversations = [];

        try {
            const url = `${baseURL}${property.livie_id}?date1=${dateDebut}&date2=${dateFin}&format=${exportFormat}`;
            console.log(url);
            const response = await axios.get(url);
            const nbToken = response.data.data[0].nb_token;
            response.data.data.forEach((d) => {
                d.chats.forEach((chat) => {
                    let conversation = {
                        chatId: chat.chat.id,
                        interactions: [],
                        hotelName: property.name,
                        dateConversation: dayjs(chat.chat.updated_at).format("DD/MM/YYYY"),
                        heureConversation: dayjs(chat.chat.updated_at).format("HH:mm:ss"),
                        nb_token: nbToken,
                    };
                    chat.events.forEach((event, index) => {
                        if (event.type === "user_input") {
                            const nextEvent = chat.events.find((e, idx) => idx > index && e.type === "chatbot_parametrized" && e.params?.result);
                            if (nextEvent) {
                                conversation.interactions.push({
                                    userQuestion: event.params.input,
                                    chatbotResponse: nextEvent.params.result,
                                });
                            }
                        }
                    });
                    if (conversation.interactions.length > 0) {
                        allConversations.push(conversation);
                    }
                });
            });
        } catch (error) {
            console.error("Erreur lors de la récupération des conversations pour l'hôtel", property.name, error);
        }

        let exportData;
        if (exportFormat === "json") {
            exportData = JSON.stringify(allConversations);
        } else {
            exportData = formatCSV(allConversations);
        }

        let dateStart = dayjs(startDate).format("DD-MM-YYYY");
        let dateEnd = dayjs(endDate).format("DD-MM-YYYY");

        const nomFichierPrefix = `export-${exportFormat}-conversations-${property.name.toLowerCase()}-${dateStart}-au-${dateEnd}`;
        const fichierExtension = exportFormat === "json" ? "json" : "csv";
        const nomFichierComplet = `${nomFichierPrefix}.${fichierExtension}`;

        const blob = new Blob([exportData], {
            type: exportFormat === "json" ? "application/json" : "text/csv",
        });
        saveAs(blob, nomFichierComplet);

        setIsLoading(false);
    };

    const formatCSV = (arr) => {
        let csvInformations =
            "Chat ID,Nom de l'hôtel,Date de la conversation,Heure de la conversation,Question de l'utilisateur,Réponse du chatbot, Token\n";
        arr.forEach((conversation) => {
            conversation.interactions.forEach((interaction) => {
                csvInformations += `"${conversation.chatId}","${conversation.hotelName}","${conversation.dateConversation}","${
                    conversation.heureConversation
                }","${interaction.userQuestion.replace(/"/g, '""')}","${interaction.chatbotResponse.replace(/"/g, '""')}","${
                    conversation.nb_token
                }"\n`;
            });
        });
        return csvInformations;
    };

    return (
        <div className="exportWrapper">
            {!isLoading && (
                <select value={exportFormat} onChange={(e) => setExportFormat(e.target.value)}>
                    <option value="json">JSON</option>
                    <option value="csv">CSV</option>
                </select>
            )}
            <button id="bouton-export" onClick={handleExport} disabled={isLoading} className={isLoading ? "loading" : "en-attente"}>
                {isLoading ? (
                    <>
                        <span className="bouton-text">Téléchargement en cours...</span>
                        <CircularProgress size={20} color="inherit" />
                    </>
                ) : (
                    <>
                        <span className="bouton-text">Export {exportFormat.toUpperCase()}</span>
                        <svg
                            className="bouton-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            viewBox="0 0 256 256"
                        >
                            <path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-69.66,42.34L160,188.69V128a8,8,0,0,0-16,0v60.69l-18.34-18.35a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Z"></path>
                        </svg>
                    </>
                )}
            </button>
        </div>
    );
};

export default CustomExportConversations;
