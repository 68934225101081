import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomOption from "./CustomOption";
import { useSelector } from 'react-redux';
import { selectPeriod } from '../../reducers/filterSlice';

const CustomSelectInput = ({ dateRanges, onSelectPeriod }) => {

    const period = useSelector(selectPeriod);
    const [items, setItems] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(period);

    useEffect(() => {
        const newItems = [
            { value: 'data_1', label: 'Période -1', date_range: dateRanges[1], isResetOption: false },
            { value: 'data_2', label: 'Période -2', date_range: dateRanges[2], isResetOption: false },
            { value: 'data_3', label: 'Année précédente', date_range: dateRanges[3], isResetOption: false },
        ];
        setItems(newItems);
        if (period) {
            const matchingItem = newItems.find(item => item.value === period);
            setSelectedPeriod(matchingItem ? matchingItem.value : null);
        }
    }, [dateRanges, period]);

    const handleChange = (selectedOption) => {
        setSelectedPeriod(selectedOption.value);
        onSelectPeriod(selectedOption.value);
    };

    return (
        <>
            <Select
                name="period-select"
                isRequired={true}
                options={items}
                onChange={handleChange}
                value={items.find(item => item.value === selectedPeriod)}
                className="basic-single single-search-input no-padding period-select"
                classNamePrefix="select"
                placeholder="Comparer les périodes (ex : Période - 1)"
                components={{ Option: CustomOption }}
            />
        </>
    );
}

export default CustomSelectInput;
