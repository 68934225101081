import React, { useMemo } from "react";
import Select, { components } from "react-select";
import CustomOption from "./CustomOption";
import iconeLoupe from '../../assets/icons/search.svg';

const CustomIcone = (props) => (
  <components.DropdownIndicator {...props}>
    <img src={iconeLoupe} alt="Icône Loupe - Monitor Altelis" />
  </components.DropdownIndicator>
);

const CustomSearchInput = ({entity, isMultiSearch, labelType, onChange, className, value = true }) => {

    const items = useMemo(() => {
        return entity?.map((item) => {
            switch (labelType) {
                case 'user':
                    return { ...item, value: item.id, label: item.name };
                default:
                    return { ...item, value: item.Id, label: item.label };
            }
        }) || [];
    }, [entity, labelType]);

    return (
        <Select
            isMulti={isMultiSearch}
            options={items}
            onChange={onChange}
            value={value}
            className={`${isMultiSearch ? "basic-multi-select multi-search-input" : "basic-single single-search-input"} ${className}`}
            classNamePrefix="select"
            placeholder="Rechercher une propriété..."
            components={{ Option: CustomOption, DropdownIndicator: CustomIcone }}
        />
    );
}

export default CustomSearchInput;
