import { Routes, Route } from "react-router-dom";
import CustomerPanel from "../../customers/CustomerPanel";
import PropertyList from "./PropertyList";

const Properties = () => {
  return (
    <Routes>
      <Route path=":propertyId/*" element={<CustomerPanel adminMode />} />
      <Route path="*" element={<PropertyList />} />
    </Routes>
  );
};

export default Properties;
