import React, { useState, useEffect } from "react";
import CustomBigCard from "../custom/CustomBigCard";
import ReservationsMMIP from "./ReservationsMMIP";
import { get_my_maison_reservations } from "../../api";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { selectEndDate, selectStartDate } from "../../reducers/filterSlice";

const MetaMMIP = ({ period, apiData }) => {
    const [reservations, setReservations] = useState([]);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    const formatReservationsData = (reservations) => {
        console.log("Formatage des données de réservation :", reservations);
        return reservations
            .sort((a, b) => new Date(b.dateReservation) - new Date(a.dateReservation))
            .map((reservation) => ({
                reference: reservation.reference,
                date_arrivee: dayjs(reservation.checkInDate).format("DD/MM/YYYY"),
                date_depart: dayjs(reservation.checkOutDate).format("DD/MM/YYYY"),
                date_resa: dayjs(reservation.dateReservation).format("DD/MM/YYYY"),
                montant_resa: reservation.totalAmount || 0,
                channelCode: reservation.channelCode,
            }));
    };

    useEffect(() => {
        console.log("Récupération des réservations pour My Maison In Paris...");
        setIsLoading(true);

        get_my_maison_reservations(dayjs(startDate).format("YYYY-MM-DD"), dayjs(endDate).format("YYYY-MM-DD"))
            .then((response) => {
                console.log("Réponse de l'API reçue :", response);
                if (response && response.length > 0) {
                    const formattedReservations = formatReservationsData(response);
                    console.log("Réservations formatées :", formattedReservations);

                    const metaReservations = formattedReservations.filter((res) => res.channelCode === "Metasearch_googlehpa");

                    setReservations(metaReservations);

                    const totalAmount = metaReservations.reduce((sum, r) => sum + r.montant_resa, 0);
                    const averageBasket = metaReservations.length > 0 ? totalAmount / metaReservations.length : 0;

                    const combinedData = {
                        totalReservations: metaReservations.length,
                        totalAmount: totalAmount,
                        averageBasket: averageBasket,
                        meta_cost: apiData.meta?.meta_cost || null,
                    };

                    console.log("Données combinées avec meta_cost :", combinedData);
                    setData(combinedData);
                } else {
                    console.warn("Aucune réservation trouvée pour la période spécifiée.");
                    setData({
                        totalReservations: 0,
                        totalAmount: 0,
                        averageBasket: 0,
                        meta_cost: apiData.meta?.meta_cost || null,
                    });
                    setReservations([]);
                }
            })
            .catch((error) => {
                console.error("Erreur lors de l'appel à l'API :", error);
                setData({
                    totalReservations: 0,
                    totalAmount: 0,
                    averageBasket: 0,
                    meta_cost: apiData.meta?.meta_cost || null,
                });
                setReservations([]);
            })
            .finally(() => {
                console.log("Fin de la récupération des réservations.");
                setIsLoading(false);
            });
    }, [startDate, endDate, apiData.meta]);

    if (isLoading) {
        return (
            <div className="loader-container">
                <CircularProgress size={30} />
            </div>
        );
    }

    console.log("Données finales du composant (prêtes pour affichage) :", data);

    return (
        <div className="ads-container">
            <div className="ads-row">
                <CustomBigCard
                    data_first={{
                        label: "Montant des réservations",
                        data_0: data?.totalAmount || 0,
                        tooltip: "Les montants des réservations obtenus pour My Maison In Paris.",
                        attribut: "€",
                    }}
                    data_second={{
                        label: "Nombre de réservations",
                        data_0: data?.totalReservations || 0,
                    }}
                    data_third={{
                        label: "Panier moyen",
                        data_0: data?.averageBasket || 0,
                        attribut: "€",
                    }}
                    period={period}
                    tooltip={data?.meta_cost?.datesRange?.[0]?.tooltip || ""}
                />
                <CustomBigCard
                    data_first={{
                        label: "Coût",
                        attribut: "€",
                        data_0: data.meta_cost?.datesRange?.[0]?.cost || 0,
                        data_1: data.meta_cost?.datesRange?.[1]?.cost || 0,
                        data_2: data.meta_cost?.datesRange?.[2]?.cost || 0,
                        data_3: data.meta_cost?.datesRange?.[3]?.cost || 0,
                        tooltip: "Le coût correspond à la somme de vos dépenses au cours de cette période.",
                    }}
                    data_second={{
                        label: "Equiv. commission",
                        attribut: "%",
                        decimals: 2,
                        data_0:
                            data?.totalAmount && data.meta_cost?.datesRange?.[0]?.cost
                                ? (1 / (data.totalAmount / data.meta_cost.datesRange[0].cost)) * 100
                                : 0,
                        data_1:
                            data?.totalAmount && data.meta_cost?.datesRange?.[1]?.cost
                                ? (1 / (data.totalAmount / data.meta_cost.datesRange[1].cost)) * 100
                                : 0,
                        data_2:
                            data?.totalAmount && data.meta_cost?.datesRange?.[2]?.cost
                                ? (1 / (data.totalAmount / data.meta_cost.datesRange[2].cost)) * 100
                                : 0,
                        data_3:
                            data?.totalAmount && data.meta_cost?.datesRange?.[3]?.cost
                                ? (1 / (data.totalAmount / data.meta_cost.datesRange[3].cost)) * 100
                                : 0,
                    }}
                    data_third={{
                        label: "ROI",
                        decimals: 2,
                        data_0: data?.totalAmount && data.meta_cost?.datesRange?.[0]?.cost ? data.totalAmount / data.meta_cost.datesRange[0].cost : 0,
                        data_1: data?.totalAmount && data.meta_cost?.datesRange?.[1]?.cost ? data.totalAmount / data.meta_cost.datesRange[1].cost : 0,
                        data_2: data?.totalAmount && data.meta_cost?.datesRange?.[2]?.cost ? data.totalAmount / data.meta_cost.datesRange[2].cost : 0,
                        data_3: data?.totalAmount && data.meta_cost?.datesRange?.[3]?.cost ? data.totalAmount / data.meta_cost.datesRange[3].cost : 0,
                    }}
                    period={period}
                    isInvert
                />
            </div>
            <ReservationsMMIP data={reservations} period={period} />
        </div>
    );
};

export default MetaMMIP;
