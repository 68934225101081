import React, { useState } from "react";
import CustomCard from "../custom/CustomCard";
import CustomList from "../custom/CustomList";
import dayjs from "dayjs";

const Analytics = ({ data, period, property }) => {
    const [formattedData, setFormattedData] = useState();

    React.useEffect(() => {
        if (data) {
            if (data[1]["totals"] !== null) {
                setFormattedData({
                    details: data[1]["rows"].sort((a, b) => b["metrics"][1] - a["metrics"][1]).slice(0, 5),
                    totals: [
                        {
                            label: "Utilisateurs",
                            data_0: data[1]["totals"] ? data[1]["totals"][0] : 0,
                            data_1: data[3]["totals"] ? data[3]["totals"][0] : 0,
                            data_2: data[5]["totals"] ? data[5]["totals"][0] : 0,
                            data_3: data[7]["totals"] ? data[7]["totals"][0] : 0,
                            tooltip: "Utilisateurs qui ont initié au moins une session dans la plage de dates sélectionnée.",
                            isNotRound: true,
                        },
                        {
                            label: "Pages vues",
                            data_0: data[1]["totals"] ? data[1]["totals"][1] : 0,
                            data_1: data[3]["totals"] ? data[3]["totals"][1] : 0,
                            data_2: data[5]["totals"] ? data[5]["totals"][1] : 0,
                            data_3: data[7]["totals"] ? data[7]["totals"][1] : 0,
                            tooltip:
                                "Il s'agit du nombre total de pages consultées. Les visites répétées d'un internaute sur une même page sont prises en compte.",
                            isNotRound: true,
                        },
                        {
                            label: "Session moyenne",
                            isTime: true,
                            data_0: data[1]["totals"]
                                ? property?.ga_type === "universal"
                                    ? data[1]["totals"][3] / data[1]["totals"][4]
                                    : data[1]["totals"][3]
                                : 0,
                            data_1: data[3]["totals"]
                                ? property?.ga_type === "universal"
                                    ? data[3]["totals"][3] / data[3]["totals"][4]
                                    : data[3]["totals"][3]
                                : 0,
                            data_2: data[5]["totals"]
                                ? property?.ga_type === "universal"
                                    ? data[5]["totals"][3] / data[5]["totals"][4]
                                    : data[5]["totals"][3]
                                : 0,
                            data_3: data[7]["totals"]
                                ? property?.ga_type === "universal"
                                    ? data[7]["totals"][3] / data[7]["totals"][4]
                                    : data[7]["totals"][3]
                                : 0,
                            tooltip:
                                "Le temps moyen passé par un utilisateur au cours d'une session. Les visites répétées d'un internaute sur une même page sont prises en compte.",
                        },
                        {
                            label: "Taux de rebond",
                            attribut: "%",
                            isInvert: true,
                            data_0: data[1]["totals"] ? (property?.ga_type === "universal" ? data[1]["totals"][2] : data[1]["totals"][2] * 100) : 0,
                            data_1: data[3]["totals"] ? (property?.ga_type === "universal" ? data[3]["totals"][2] : data[3]["totals"][2] * 100) : 0,
                            data_2: data[5]["totals"] ? (property?.ga_type === "universal" ? data[5]["totals"][2] : data[5]["totals"][2] * 100) : 0,
                            data_3: data[7]["totals"] ? (property?.ga_type === "universal" ? data[7]["totals"][2] : data[7]["totals"][2] * 100) : 0,
                            tooltip:
                                "Pourcentage de sessions avec consultation d'une seule page de votre site au cours desquelles aucune interaction n'a été enregistrée. Une session avec rebond dure 0 seconde.",
                        },
                    ],
                });
            }
        }
    }, [data]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Pays",
                accessor: "dimensions.0",
                icon: "country",
            },
            {
                Header: "Utilisateurs",
                accessor: "metrics.0",
                class: "border",
                icon: "user",
                Cell: ({ cell: { value } }) => <span>{Number(value).toLocaleString()}</span>,
            },
            {
                Header: "Pages vues",
                accessor: "metrics.1",
                icon: "view",
                Cell: ({ cell: { value } }) => <span>{Number(value).toLocaleString()}</span>,
            },
            {
                Header: "Session moyenne",
                accessor: "metrics",
                icon: "clock",
                Cell: ({ cell: { value } }) => (
                    <span>
                        {property?.ga_type === "universal"
                            ? dayjs.duration(value[3] / value[4], "second").format("HH:mm:ss")
                            : dayjs.duration(value[3], "second").format("HH:mm:ss")}
                    </span>
                ),
            },
            {
                Header: "Taux de rebond",
                accessor: "metrics.2",
                icon: "bounce",
                Cell: ({ cell: { value } }) => (
                    <span>
                        {Number(property?.ga_type === "universal" ? value : value * 100).toLocaleString("fr-FR", { maximumFractionDigits: 2 })} %
                    </span>
                ),
            },
        ],
        []
    );

    return formattedData ? (
        <div className="transactions-container">
            <div className="analytics-row">
                {formattedData.totals.map((row, index) => (
                    <CustomCard key={index} data={row} tooltip={row.tooltip} period={period} isTime={row.isTime} isInvert={row.isInvert} />
                ))}
            </div>
            <div className="input-orders-container">
                <CustomList
                    entity={formattedData.details}
                    columns={columns.filter((column) => !column.hide)}
                    isFetchable={false}
                    isSelectable={false}
                    isPaginable={false}
                />
            </div>
        </div>
    ) : (
        <div className="analytics-row is_big">
            <p>
                Vous ne disposez pas de compte Google Analytics ou nous n'avons pas d'accès administrateur à ce compte.
                <br />
                Veuillez contacter notre support sur l'adresse{" "}
                <a target="_blank" rel="noreferrer noopener" href="mailto: support@altelis.com">
                    support@altelis.com
                </a>{" "}
                si vous souhaitez voir ces informations.
            </p>
        </div>
    );
};

export default Analytics;
