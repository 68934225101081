import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialEndDate = dayjs()
    .subtract(1, "month")
    .endOf("month")
    .format("YYYY-MM-DD");
const initialStartDate = dayjs()
    .startOf("month")
    .subtract(1, "month")
    .format("YYYY-MM-DD");

const initialState = {
    period: "data_0",
    endDate: initialEndDate,
    startDate: initialStartDate,
    filters: [],
    search: null,
};

export const filterSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        changePeriod: (state, action) => {
            state.period = action.payload;
        },
        changeStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        changeEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        eraseSearch: (state) => {
            state.search = null;
        },
        setInitial: (state) => {
            state.endDate = initialEndDate;
            state.startDate = initialStartDate;
            state.period = initialState.period;
            state.filters = initialState.filters;
            state.search = null;
        },
    },
});

export const {
    changePeriod,
    changeStartDate,
    changeEndDate,
    setSearch,
    eraseSearch,
    setInitial,
    setFilters,
} = filterSlice.actions;

export const selectPeriod = (state) => state.filters.period;
export const selectStartDate = (state) => state.filters.startDate;
export const selectEndDate = (state) => state.filters.endDate;
export const selectSearch = (state) => state.filters.search;
export const selectFilters = (state) => state.filters.filters;

export default filterSlice.reducer;
