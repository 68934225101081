import React, { useEffect, useReducer, useCallback, useMemo } from "react";
import "./Login.scss";
import LogoAltelis from "../../assets/icons/logo-altelis-v2.svg";
import IlluLogin from "../../assets/images/illu-login.gif";
import { useNavigate, Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../reducers/userSlice";
import axios from "axios";

const initialState = {
    email: "",
    password: "",
    error: "",
    loader: false,
    redirectToReferrer: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_EMAIL":
            return { ...state, email: action.payload, error: "" };
        case "SET_PASSWORD":
            return { ...state, password: action.payload, error: "" };
        case "SET_ERROR":
            return { ...state, error: action.payload };
        case "SET_LOADER":
            return { ...state, loader: action.payload };
        case "REDIRECT":
            return { ...state, redirectToReferrer: true };
        default:
            return state;
    }
};

const Login = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { email, password, error, loader, redirectToReferrer } = state;
    const user = useSelector(selectUser);
    const navigate = useNavigate();

    useEffect(() => {
        if (redirectToReferrer) {
            user.role !== "admin"
                ? navigate(`/admin/monitor/details/${user.client_id_api}`)
                : navigate("/admin/details");
        }
    }, [redirectToReferrer, user, navigate]);

    const handleSubmit = useCallback(
        (event) => {
            if (event.type === "click" || event.key === "Enter") {
                dispatch({ type: "SET_LOADER", payload: true });
                axios
                    .post(
                        process.env.REACT_APP_BACKEND_API_URL +
                            "accounts/log_in/",
                        { email, password },
                        { withCredentials: true }
                    )
                    .then(() => {
                        navigate(0);
                    })
                    .catch((err) => {
                        dispatch({
                            type: "SET_ERROR",
                            payload: "Identifiants incorrects",
                        });
                        dispatch({ type: "SET_LOADER", payload: false });
                        console.log(err);
                    });
            }
        },
        [email, password, navigate]
    );

    const isEmailValid = useMemo(() => /\S+@\S+\.\S+/.test(email), [email]);

    return (
        <div className="main-login">
            <div className="login-background" />
            <div className="login-container">
                <div className="login-left">
                    <img src={IlluLogin} alt="Illustration page login"></img>
                    <p>
                        Boostez votre hôtel avec des statistiques
                        incontournables Direct, Livie, Google Ads, Métas...
                    </p>
                </div>
                <div className="login-form">
                    <div className="img-container">
                        <img src={LogoAltelis} alt="Logo Altelis Monitor" />
                    </div>
                    <div className="login-form-right">
                        <h1>Bienvenue sur Altelis Monitor 👋</h1>
                        <p className="welcome-message">
                            Veuillez rentrer vos identifiants pour vous
                            connecter
                        </p>
                        {error && <p className="login-error">{error}</p>}
                        <div className="login-input-group">
                            <label>
                                Email <span className="required"> *</span>
                            </label>
                            <input
                                type="email"
                                placeholder="Saisissez votre adresse email"
                                value={email}
                                onChange={(e) =>
                                    dispatch({
                                        type: "SET_EMAIL",
                                        payload: e.target.value,
                                    })
                                }
                                onKeyUp={(e) => handleSubmit(e)}
                                className={!isEmailValid ? "invalid-input" : ""}
                            />
                        </div>
                        <div className="login-input-group">
                            <label>
                                Mot de passe{" "}
                                <span className="required"> *</span>
                            </label>
                            <input
                                type="password"
                                placeholder="Saisissez votre mot de passe"
                                value={password}
                                onChange={(e) =>
                                    dispatch({
                                        type: "SET_PASSWORD",
                                        payload: e.target.value,
                                    })
                                }
                                onKeyUp={(e) => handleSubmit(e)}
                            />
                        </div>
                        <Link
                            to={process.env.REACT_APP_BACKEND_URL}
                            className="forgot-password"
                            target="_blank"
                        >
                            Mot de passe oublié ?
                        </Link>
                        <div className="login-button-container">
                            {loader ? (
                                <button
                                    className="btn btn-primary login-button-loading"
                                    disabled
                                >
                                    <CircularProgress
                                        size={20}
                                        className="white-loader"
                                    />
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary login-button"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Se connecter
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
